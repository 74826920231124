import { render, staticRenderFns } from "./Duel.vue?vue&type=template&id=963c1648&scoped=true&"
import script from "./Duel.vue?vue&type=script&lang=js&"
export * from "./Duel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "963c1648",
  null
  
)

export default component.exports
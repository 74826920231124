<template>


  <div class="page-content-inner pt-lg-0  pr-lg-0 mt-4">
    <h3>Düello Meydanı</h3>

    <div class="ro" uk-grid>

      <div class="uk-width-1-3@m mt-2">
        <div class="bg-white uk-card-default">
          <div class="p-3 row align-items-center">

            <div class="col">

              <h5><i class="uil-user-circle"></i> Düelloya Davet Et</h5>

              <hr class="m-0">

              <input class="mt-3" type="text" value="" placeholder="Kullanıcı Ara">

              <h6>Çevrimiçi Kullanıcılar</h6>
              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Reyhan Ataklı</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Ali Sevinç</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-1.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Şeyda Hüryürek</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Şeyda Hüryürek</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-1.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Şeyda Hüryürek</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Şeyda Hüryürek</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Şeyda Hüryürek</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                <div class="uk-width-auto position-relative">
                  <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-1.jpg'">
                  <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                </div>
                <div class="uk-width-expand">
                  <p class="uk-text-truncate mb-0"> Şeyda Hüryürek</p>
                </div>
                <div>
                  <span class="btn-option userlist">
                    <i class="icon-feather-more-vertical"></i>
                  </span>
                  <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                    <ul>
                      <li>
                        <span>
                          <i class="uil-user"></i>
                          Profili İncele</span>
                      </li>
                      <li>
                        <span>
                          <i class="uil-bolt-alt"></i>
                          Düello Daveti Gönder</span>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>









              <a href="#modal-overflow" uk-toggle>
                <p class="uk-heading-line uk-text-center mt-2 uk-text-small"><span><i class="uil-list-ul"></i> Tüm
                    Kullanıcıları Göster
                  </span>
                </p>
              </a>


              <div id="modal-overflow" uk-modal>
                <div class="uk-modal-dialog"> <button class="uk-modal-close-default" type="button" uk-close></button>
                  <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Tüm Kullanıcı Listesi</h2>
                  </div>

                  <div class="uk-modal-body" uk-overflow-auto>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                        <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                        <span class="avatar-child avatar-badge bg-success rounded-circle"></span>
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="uk-grid-small uk-flex-middle position-relative" uk-grid>
                      <div class="uk-width-auto position-relative">
                        <img class="rounded-circle avatar-sm" :src="mediaBase + '/images/avatars/avatar-2.jpg'">
                      </div>
                      <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Ahmet Gültekinoğullarından</p>
                      </div>
                      <div>
                        <span class="btn-option userlist">
                          <i class="icon-feather-more-vertical"></i>
                        </span>
                        <div class="dropdown-option-nav" uk-dropdown="pos: bottom-right ;mode : hover">
                          <ul>
                            <li>
                              <span>
                                <i class="uil-user"></i>
                                Profili İncele</span>
                            </li>
                            <li>
                              <span>
                                <i class="uil-bolt-alt"></i>
                                Düello Daveti Gönder</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>



                  </div>





                </div>
              </div>





            </div>
          </div>

          <!--<hr class="m-0">

            <div class="p-3">
                <h5> Total Sales</h5>
                <h2> 830.98$</h2>
                <canvas id="cjs-linechart"></canvas>
            </div>

            <hr class="m-0">-->

          <!--<div class="p-3">
                <h5> Latest Enrolled</h5>

                <div class="uk-grid-small uk-flex-middle" uk-grid>
                    <div class="uk-width-auto"> <img class="rounded border border-200"
                            src="../assets/images/course/2.png" width="60" alt=""> </div>
                    <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Javascript for Begginers</p>
                    </div>
                    <div class="uk-width-auto"> <span class="badge badge-soft-success mt-n1">
                            +12.9$</span>
                    </div>
                </div>

                <div class="uk-grid-small uk-flex-middle" uk-grid>
                    <div class="uk-width-auto"> <img class="rounded border border-200"
                            src="../assets/images/course/2.png" width="60" alt=""> </div>
                    <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Javascript for Begginers</p>
                    </div>
                    <div class="uk-width-auto"> <span class="badge badge-soft-success mt-n1">
                            +12.9$</span>
                    </div>
                </div>

                <div class="uk-grid-small  uk-flex-middle" uk-grid>
                    <div class="uk-width-auto"> <img class="rounded border border-200"
                            src="../assets/images/course/3.png" width="60" alt=""> </div>
                    <div class="uk-width-expand">
                        <p class="uk-text-small uk-text-truncate mb-0"> Learn Bootstrap framework</p>
                    </div>
                    <div class="uk-width-auto"> <span class="badge badge-soft-success mt-n1">
                            +10.9$</span>
                    </div>
                </div>





                <a href="#">
                    <p class="uk-heading-line uk-text-center mt-2 uk-text-small"><span> Veiw more
                        </span>
                    </p>
                </a>


                <h5> Top Sales</h5>

                <div class="uk-grid-small uk-flex-middle" uk-grid>
                    <div class="uk-width-auto"> <img class="rounded border border-200"
                            src="../assets/images/course/2.png" width="80" alt=""> </div>
                    <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Javascript for Begginers</p>
                        <span class="badge badge-soft-secondary mt-n1"> 5 enrolled</span>
                    </div>

                </div>

                <div class="uk-grid-small uk-flex-middle" uk-grid>
                    <div class="uk-width-auto"> <img class="rounded border border-200"
                            src="../assets/images/course/2.png" width="80" alt=""> </div>
                    <div class="uk-width-expand">
                        <p class="uk-text-truncate mb-0"> Javascript for Begginers</p>
                        <span class="badge badge-soft-secondary mt-n1"> 5 enrolled</span>
                    </div>

                </div>

                <div class="uk-grid-small  uk-flex-middle" uk-grid>
                    <div class="uk-width-auto"> <img class="rounded border border-200"
                            src="../assets/images/course/3.png" width="60" alt=""> </div>
                    <div class="uk-width-expand">
                        <p class="uk-text-small uk-text-truncate mb-0"> Learn Bootstrap framework</p>
                    </div>
                    <div class="uk-width-auto"> <span class="badge badge-soft-secondary mt-n1"> 1
                            enrolled</span>
                    </div>
                </div>





                <a href="#">
                    <p class="uk-heading-line uk-text-center mt-2 uk-text-small"><span> Veiw more
                        </span>
                    </p>
                </a>

            </div>-->

        </div>

      </div>



      <div class="uk-width-expand@m">

        <!-- Popular Instructers -->
        <div class="section-small pt-0">

          <div class="uk-position-relative uk-visible-toggle " tabindex="-1" uk-slider="finite: true">

            <div class="grid-slider-header">
              <h4><i class="uil-envelope"></i> Düello Davetleri</h4>
              <div class="grid-slider-header-link">

                <!-- <a href="browse-channals.html" class="btn uk-visible@m"> View all </a> -->
                <a href="#" class="slide-nav-prev" uk-slider-item="previous"></a>
                <a href="#" class="slide-nav-next" uk-slider-item="next"></a>

              </div>
            </div>
            <ul id="invite" class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s pb-3 uk-grid">

              <li>
                <div class="card">
                  <div class="card-header">
                    <span class="uk-text-small">Çevrimiçi Düello</span>
                  </div>
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img alt="Image placeholder" :src="mediaBase + '/images/avatars/avatar-2.jpg'"
                        class="avatar  rounded-circle avatar-lg">
                      <span class="avatar-child avatar-badge bg-success"></span>
                    </div>
                    <h5 class="h6 mt-4 mb-0"> Sinan Gümüş </h5>
                    <span class="d-block text-sm text-muted mb-2">"Telefonda Etkili İletişim<br>"</span>

                    <div class="d-flex justify-content-between px-4">
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Profili İncele">
                        <i class="uil-user"></i> </a>
                      <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.send_message	')">
                        <i class="uil-comment-message"></i> </a>
                      <a uk-toggle="target: #modal-example" class="btn btn-icon btn-hover btn-circle"
                        uk-tooltip="Daveti İncele">
                        <i class="uil-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div class="card-footer text-center py-2">
                    <span class="uk-text-small online" style="color:blueviolet;"><i class="uil-wifi"></i> Düelloya
                      bekliyor!..</span>
                  </div>
                  <div id="modal-example" uk-modal>
                    <div class="uk-modal-dialog uk-modal-body">
                      <h2 class="uk-modal-title">Düello Davetiyesi</h2>
                      <p><b>Sinan Gümüş</b>
                      </p>
                      <p class="uk-text-right"> <button class="uk-button uk-button-default uk-modal-close"
                          type="button">Reddet</button> <button class="uk-button uk-button-primary" type="button">Kabul
                          Et</button> </p>
                    </div>
                  </div>

                </div>
              </li>

              <li>
                <div class="card">
                  <div class="card-header">
                    <span class="uk-text-small">Çevrimdışı Düello</span>
                  </div>
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img alt="Image placeholder" :src="mediaBase + '/images/avatars/avatar-1.jpg'"
                        class="avatar  rounded-circle avatar-lg">
                    </div>
                    <h5 class="h6 mt-4 mb-0">Selma Güler </h5>
                    <span class="d-block text-sm text-muted mb-2">İş Sağlığı ve<br>Güvenliği</span>

                    <div class="d-flex justify-content-between px-4">
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Profili İncele">
                        <i class="uil-user"></i> </a>
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Mesaj Gönder">
                        <i class="uil-comment-message"></i> </a>
                      <a uk-toggle="target: #modal-example" class="btn btn-icon btn-hover btn-circle"
                        uk-tooltip="Daveti İncele">
                        <i class="uil-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div class="card-footer text-center py-2">
                    <span class="text-muted uk-text-small">Katılım süresi 00:35:11</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="card">
                  <div class="card-header">
                    <span class="uk-text-small">Çevrimdışı Düello</span>
                  </div>

                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img alt="Image placeholder" :src="mediaBase + 'assets/images/avatars/avatar-2.jpg'"
                        class="avatar  rounded-circle avatar-lg">
                      <span class="avatar-child avatar-badge bg-success"></span>
                    </div>
                    <h5 class="h6 mt-4 mb-0"> Ahmet Gündöner </h5>
                    <span class="d-block text-sm text-muted mb-3">"Liderlik Eğitimi<br>"</span>

                    <div class="d-flex justify-content-between px-4">
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Profili İncele">
                        <i class="uil-user"></i> </a>
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Mesaj Gönder">
                        <i class="uil-comment-message"></i> </a>
                      <a uk-toggle="target: #modal-example" class="btn btn-icon btn-hover btn-circle"
                        uk-tooltip="Daveti İncele">
                        <i class="uil-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div class="card-footer text-center py-2">
                    <span class="text-muted uk-text-small">Katılım süresi: 15:45:09 </span>

                  </div>
                </div>
              </li>

              <li>
                <div class="card">
                  <div class="card-header">
                    <span class="uk-text-small">Çevrimdışı Düello</span>
                  </div>
                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img alt="Image placeholder" :src="mediaBase + '/images/avatars/avatar-1.jpg'"
                        class="avatar  rounded-circle avatar-lg">
                    </div>
                    <h5 class="h6 mt-4 mb-0">Selma Güler </h5>
                    <span class="d-block text-sm text-muted mb-2">İş Sağlığı ve<br>Güvenliği</span>

                    <div class="d-flex justify-content-between px-4">
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Profili İncele">
                        <i class="uil-user"></i> </a>
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Mesaj Gönder">
                        <i class="uil-comment-message"></i> </a>
                      <a uk-toggle="target: #modal-example" class="btn btn-icon btn-hover btn-circle"
                        uk-tooltip="Daveti İncele">
                        <i class="uil-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div class="card-footer text-center py-2">
                    <span class="text-muted uk-text-small">Katılım süresi 00:35:11</span>
                  </div>
                </div>
              </li>

              <li>
                <div class="card">
                  <div class="card-header">
                    <span class="uk-text-small">Çevrimdışı Düello</span>
                  </div>

                  <div class="card-body text-center">
                    <div class="avatar-parent-child">
                      <img alt="Image placeholder" :src="mediaBase + '/images/avatars/avatar-2.jpg'"
                        class="avatar  rounded-circle avatar-lg">
                      <span class="avatar-child avatar-badge bg-success"></span>
                    </div>
                    <h5 class="h6 mt-4 mb-0"> Ahmet Gündöner </h5>
                    <span class="d-block text-sm text-muted mb-2">"Liderlik Eğitimi<br>"</span>

                    <div class="d-flex justify-content-between px-4">
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Profili İncele">
                        <i class="uil-user"></i> </a>
                      <a href="#" class="btn btn-icon btn-hover btn-circle" uk-tooltip="Mesaj Gönder">
                        <i class="uil-comment-message"></i> </a>
                      <a uk-toggle="target: #modal-example" class="btn btn-icon btn-hover btn-circle"
                        uk-tooltip="Daveti İncele">
                        <i class="uil-external-link-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div class="card-footer text-center py-2">
                    <span class="text-muted uk-text-small">Katılım süresi: 15:45:09 </span>

                  </div>
                </div>
              </li>






            </ul>

          </div>

        </div>
        <hr class="m-0">


        <div class="section-small">

          <h4><i class="uil-book-open"></i> Eğitim Düellosu</h4>

          <div class="uk-position-relative uk-visible-toggle " tabindex="-1" uk-slider="finite: true">

            <ul id="challange-edu" class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s uk-grid">

              <li>
                <div class="card">
                  <div class="card-body">
                    <div class="uk-flex-middle" uk-grid>
                      <div class="uk-width-auto">
                        <h5 class="mb-2"> Liderlik Stilleri</h5>
                        <span class="badge badge-secondary mt-n1"><i class="uil-user"></i> 7 ortak kullanıcı</span>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer d-flex justify-content-between py-3">
                    <a href="#modal-overflow" uk-toggle class="btn btn-primary btn-sm"><i class="uil-bolt-alt"></i>
                      Düello Daveti Gönder</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="card">
                  <div class="card-body">
                    <div class="uk-flex-middle" uk-grid>
                      <div class="uk-width-auto">
                        <h5 class="mb-2"> E-posta Yazışma Kuralları</h5>
                        <span class="badge badge-secondary mt-n1"><i class="uil-user"></i> 1 ortak kullanıcı</span>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer d-flex justify-content-between py-3">
                    <a href="#modal-overflow" uk-toggle class="btn btn-primary btn-sm"><i class="uil-bolt-alt"></i>
                      Düello Daveti Gönder</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="card">
                  <div class="card-body">
                    <div class="uk-flex-middle" uk-grid>
                      <div class="uk-width-auto">
                        <h5 class="mb-2"> İş Sağlığı ve Güvenliği</h5>
                        <span class="badge badge-secondary mt-n1"><i class="uil-user"></i> 12 ortak kullanıcı</span>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer d-flex justify-content-between py-3">
                    <a href="#modal-overflow" uk-toggle class="btn btn-primary btn-sm"><i class="uil-bolt-alt"></i>
                      Düello Daveti Gönder</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="card">
                  <div class="card-body">
                    <div class="uk-flex-middle" uk-grid>
                      <div class="uk-width-auto">
                        <h5 class="mb-2"> Liderlik Stilleri</h5>
                        <span class="badge badge-secondary mt-n1"><i class="uil-user"></i> 7 ortak kullanıcı</span>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer d-flex justify-content-between py-3">
                    <a href="#modal-overflow" uk-toggle class="btn btn-primary btn-sm"><i class="uil-bolt-alt"></i>
                      Düello Daveti Gönder</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="card">
                  <div class="card-body">
                    <div class="uk-flex-middle" uk-grid>
                      <div class="uk-width-auto">
                        <h5 class="mb-2"> E-posta Yazışma Kuralları</h5>
                        <span class="badge badge-secondary mt-n1"><i class="uil-user"></i> 1 ortak kullanıcı</span>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer d-flex justify-content-between py-3">
                    <a href="#modal-overflow" uk-toggle class="btn btn-primary btn-sm"><i class="uil-bolt-alt"></i>
                      Düello Daveti Gönder</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="card">
                  <div class="card-body">
                    <div class="uk-flex-middle" uk-grid>
                      <div class="uk-width-auto">
                        <h5 class="mb-2"> İş Sağlığı ve Güvenliği</h5>
                        <span class="badge badge-secondary mt-n1"><i class="uil-user"></i> 12 ortak kullanıcı</span>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer d-flex justify-content-between py-3">
                    <a href="#modal-overflow" uk-toggle class="btn btn-primary btn-sm"><i class="uil-bolt-alt"></i>
                      Düello Daveti Gönder</a>
                  </div>
                </div>
              </li>


            </ul>


            <ul class="uk-slider-nav uk-dotnav uk-flex-center mt-3"></ul>

          </div>

          <div class="uk-child-width-1-2@m" uk-grid>

            <div>

            </div>

            <div>

            </div>



          </div>

        </div>

        <div class="uk-card-default uk-card-small rounded">
          <div class="uk-card-header py-3">
            <span class="uk-h5"><i class="uil-users-alt"></i> Düello Gruplarım</span>
            <a href="courses.html" class="uk-float-right"><i class="uil-plus"></i> Grup Oluştur </a>
          </div>

          <div class="uk-height-large" data-simplebar>


            <hr class="m-0">
            <div class="uk-grid-small p-4" uk-grid>
              <div class="uk-width-expand">
                <h5 class="mb-2"> Fenerbahçeliler Takımı <span class="badge badge-soft-success mt-n1"><i
                      class="uil-star"></i> Yeni</span></h5>
                <p class="uk-text-small mb-2"><a href="#"> Salih Yıldırım </a> tarafından
                  05.10.2020 tarihinde oluşturuldu.
                </p>
                <p class="mb-0 uk-text-small mt-3">
                  <span class="mr-3 bg-light p-2 mt-1"><i class="uil-user"></i> 7
                    Kullanıcı</span><span> <i class="uil-calendar-alt"></i> 05.10.2020 saat
                    15:30</span>
                </p>
              </div>
              <div>
                <a class="btn btn-primary disabled"><i class="uil-bolt-alt"></i> Düelloya
                  Başla</a>
              </div>
            </div>

            <hr class="m-0">

            <div class="uk-grid-small p-4" uk-grid>
              <div class="uk-width-expand">
                <h5 class="mb-2"> MİY Eylül Ayı Grubu </h5>
                <p class="uk-text-small mb-2"><a href="#"> Salih Yıldırım </a> tarafından
                  24.09.2020 tarihinde oluşturuldu.
                </p>
                <p class="mb-0 uk-text-small mt-3">
                  <span class="mr-3 bg-light p-2 mt-1"><i class="uil-user"></i> 16
                    Kullanıcı</span><span> <i class="uil-check"></i> Düello
                    tamamlandı</span>
                </p>
              </div>
              <div>
                <a href="#" class="btn btn-secondary"><i class="uil-list-ui-alt"></i> Sonuçları
                  Gör</a>
              </div>
            </div>


            <hr class="m-0">

            <div class="uk-grid-small p-4" uk-grid>
              <div class="uk-width-expand">
                <h5 class="mb-2"> MİY Ağustos Ayı Grubu </h5>
                <p class="uk-text-small mb-2"><a href="#"> Salih Yıldırım </a> tarafından
                  10.08.2020 tarihinde oluşturuldu.
                </p>
                <p class="mb-0 uk-text-small mt-3">
                  <span class="mr-3 bg-light p-2 mt-1"><i class="uil-user"></i> 8
                    Kullanıcı</span><span> <i class="uil-check"></i> Düello
                    tamamlandı</span>
                </p>
              </div>
              <div>
                <a href="#" class="btn btn-secondary"><i class="uil-list-ui-alt"></i> Sonuçları
                  Gör</a>
              </div>
            </div>







          </div>


        </div>




      </div>










    </div>
  </div>







</template>

<script>

export default {
  name: "Duel",
  components: {

  }
}
</script>

<style scoped></style>